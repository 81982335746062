import { NoPermissionError } from '@shared/constants/error'
import { getPartner } from '@common/router/index'
import { useUserStore } from '@common/store/user'

export default [
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:module(study|student)',
    name: 'StudentRedirect',
    component: () => import('../../views/pc/client/study/StudyCenterRedirect.js'),
    meta: {
      title: '学习中心',
      requiresAuth: true,
      permissionValid: (to) => {
        const { partnerId } = getPartner(to)
        const userStore = useUserStore()
        if (userStore.getTeacherOrganization(partnerId).length > 0) {
          return Promise.reject(new NoPermissionError())
        }
        return Promise.resolve()
      },
      roleStudent: true,
      mobileCallApp: true,
    },
  },

  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/study/:category(yxk)/course',
    name: 'StudentYxkCourse',
    component: () => import('../../views/pc/client/study/yxk/StudentYxkCourse.vue'),
    meta: {
      title: '学习中心 - 我的课程',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
      additionalRecord: 'StudentYxkStudyRecord',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/study/:category(yxk)/live',
    name: 'StudentYxkLive',
    component: () => import('../../views/pc/client/study/yxk/StudentYxkLive.vue'),
    meta: {
      title: '学习中心 - 我的直播',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
      additionalRecord: 'StudentYxkStudyRecord',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/study/:category(yxk)/record',
    name: 'StudentYxkStudyRecord',
    component: () => import('../../views/pc/client/study/yxk/StudentYxkStudyRecord.vue'),
    meta: {
      title: '学习中心 - 听课记录',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/study/:category(yxk)/quiz',
    name: 'StudentYxkQuiz',
    component: () => import('../../views/pc/client/study/yxk/quiz/StudentYxkQuiz.vue'),
    meta: {
      title: '学习中心 - 我的题库',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
      additionalRecord: 'StudentYxkQuiz',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/study/:category(yxk)/quiz/record',
    name: 'StudentYxkAnswerRecord',
    component: () => import('../../views/pc/client/study/yxk/StudentYxkAnswerRecord.vue'),
    meta: {
      title: '学习中心 - 答题记录',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
    },
  },

  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/study/:category(org)/course',
    name: 'StudentOrgCourse',
    component: () => import('../../views/pc/client/study/organization/course/StudentOrgCourse.vue'),
    meta: {
      title: '学习中心 - 我的课程',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
      additionalRecord: 'StudentOrgStudyRecord',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/study/:category(org)/live',
    name: 'StudentOrgLive',
    component: () => import('../../views/pc/client/study/organization/course/StudentOrgLive.vue'),
    meta: {
      title: '学习中心 - 我的直播',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
      additionalRecord: 'StudentOrgStudyRecord',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/study/:category(org)/record',
    name: 'StudentOrgStudyRecord',
    component: () => import('../../views/pc/client/study/organization/course/StudentOrgStudyRecord.vue'),
    meta: {
      title: '学习中心 - 听课记录',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/study/:category(org)/timetable',
    name: 'StudentOrgTimetable',
    component: () => import('../../views/pc/client/study/organization/timetable/StudentOrgTimetable.vue'),
    meta: {
      title: '学习中心 - 我的课表',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/study/:category(org)/quiz',
    name: 'StudentOrgQuiz',
    component: () => import('../../views/pc/client/study/organization/quiz/StudentOrgQuiz.vue'),
    meta: {
      title: '学习中心 - 我的题库',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
      additionalRecord: 'StudentOrgAnswerRecord',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/study/:category(org)/quiz/record',
    name: 'StudentOrgAnswerRecord',
    component: () => import('../../views/pc/client/study/organization/record/StudentOrgAnswerRecord.vue'),
    meta: {
      title: '学习中心 - 答题记录',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
    },
  },

  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/tool/videoNote',
    name: 'StudentToolVideoNote',
    component: () => import('../../views/pc/client/study/tools/VideoNoteView.vue'),
    meta: {
      title: '学习中心 - 视频笔记',
      requiresAuth: true,
      roleStudent: true,
      permissionValid: () => Promise.resolve(),
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/tool/questionNote',
    name: 'StudentToolQuestionNote',
    component: () => import('../../views/pc/client/study/tools/QuestionNoteView.vue'),
    meta: {
      title: '学习中心 - 题目笔记',
      requiresAuth: true,
      roleStudent: true,
      permissionValid: () => Promise.resolve(),
      mallBaseInfo: true,
    },
  },

  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/order',
    name: 'StudentOrder',
    component: () => import('../../views/pc/client/study/order/OrderView.vue'),
    meta: {
      title: '学习中心 - 我的订单',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
      mobilePage: 'MobileMyOrder',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/coupon',
    name: 'StudentCoupon',
    component: () => import('../../views/pc/client/study/activity/CouponView.vue'),
    meta: {
      title: '学习中心 - 我的优惠券',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
      mobilePage: 'MobileMyCoupon',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/activity',
    name: 'StudentActivity',
    component: () => import('../../views/pc/client/study/activity/ActivityView.vue'),
    meta: {
      title: '学习中心 - 我的活动',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
      mobilePage: 'MobileMyActivity',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/redeemWare',
    name: 'StudentRedeemWare',
    component: () => import('../../views/pc/client/study/redeemWare/RedeemWare.vue'),
    meta: {
      title: '学习中心 - 兑换商品',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
      mobilePage: 'MobileMyRedeem',
    },
  },

  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/personal/info',
    name: 'StudentPersonalInfo',
    component: () => import('../../views/pc/client/study/personal/PersonalInfo.vue'),
    meta: {
      title: '学习中心 - 个人资料',
      requiresAuth: true,
      roleStudent: true,
      permissionValid: () => Promise.resolve(),
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/personal/password',
    name: 'StudentPasswordChange',
    component: () => import('../../views/pc/client/study/personal/PasswordChange.vue'),
    meta: {
      title: '学习中心 - 修改密码',
      requiresAuth: true,
      roleStudent: true,
      permissionValid: () => Promise.resolve(),
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/personal/bind',
    name: 'StudentAccountBind',
    component: () => import('../../views/pc/client/study/personal/AccountBind.vue'),
    meta: {
      title: '学习中心 - 账号绑定',
      requiresAuth: true,
      roleStudent: true,
      permissionValid: () => Promise.resolve(),
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/personal/notification',
    name: 'StudentNotification',
    component: () => import('../../views/pc/client/study/personal/StudentNotification.vue'),
    meta: {
      hideNotificationsForceTip: true,
      title: '学习中心 - 消息通知',
      requiresAuth: true,
      roleStudent: true,
      permissionValid: () => Promise.resolve(),
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/tools/question',
    name: 'StudentToolsQuestion',
    component: () => import('../../views/pc/client/study/tools/question/StudentToolsQuestion.vue'),
    meta: {
      title: '学习工具 - 提问',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/tools/question/detail',
    name: 'StudentQuestionDetail',
    component: () => import('../../views/pc/client/study/tools/detail/StudentQuestionDetail.vue'),
    meta: {
      title: '学习工具 - 提问/笔记详情',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/study/class/teaching',
    name: 'StudentClassTeaching',
    component: () => import('../../views/pc/client/study/teaching/StudentClassTeaching.vue'),
    meta: {
      title: '学习中心 - 课程',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/class/teaching/yxkLive',
    name: 'WlyxLiveVideo',
    component: () => import('../../views/pc/client/study/yxk/components/WlyxLiveVideo.vue'),
    meta: {
      title: '学习中心 - 课程',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/class/wrongCollect/practice',
    name: 'WrongCollectPractice',
    component: () => import('../../views/pc/client/study/yxk/quiz/StudentWrongCollectPractice.vue'),
    meta: {
      title: '答题',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/student/class/quiz/detail',
    name: 'StudentQuizDetail',
    component: () => import('../../views/pc/client/study/yxk/quiz/StudentYxkQuizDetail.vue'),
    meta: {
      title: '答题',
      requiresAuth: true,
      permissionValid: () => Promise.resolve(),
      roleStudent: true,
      mallBaseInfo: true,
    },
  },

]
