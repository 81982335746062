import { RedirectError } from '@shared/constants/error'
import type { RouteLocationNormalized } from 'vue-router'
import { getToken } from '@shared/util/cookie'
import { replaceUrlParam } from '@shared/util/url'
import { getPartner, getUrlKey } from '@common/router/index'
import { useNetSchoolStore } from '@common/store/netSchool'
import { isEmbed } from '@common/util/embed.client'
import { getOrganizationByDomain } from '@/api/px/organization'
import { setUserIdentity } from '@/api/px/user'

class DomainRedirectError extends RedirectError {
  constructor(url: string | null) {
    super(null, url)
  }

  isTokenNeedCarry() {
    return getToken() && !this.url.includes('login') && !this.url.includes('withoutIdentity')
  }
}
export const redirectUrl = async (err: RedirectError) => {
  const url = err.url
  if (!url) {
    throw new Error('redirectUrl need a certain url')
  }
  let identity: undefined | string
  if (err instanceof DomainRedirectError && err.isTokenNeedCarry()) {
    const identityRes = await setUserIdentity()
    identity = encodeURIComponent(identityRes?.data || '')
  }
  location.replace(identity ? replaceUrlParam(url, 'identity', identity) : err.url)
}

export const validIndependentDomain = (to: RouteLocationNormalized) => {
  if ((import.meta.env.DEV && !import.meta.env.VITE_SITE_DOMAIN)
        || location.origin === import.meta.env.VITE_SITE_URL
  ) {
    if (to.meta['domainDetached']) {
      throw new RedirectError({ name: 'notFound' })
    }
    return Promise.resolve()
  }
  return getOrganizationByDomain(import.meta.env.DEV ? import.meta.env.VITE_SITE_DOMAIN : undefined)
    .then((res) => {
      if (res.data) {
        to.params['partnerType'] = to.meta['partnerType'] = String(res.data.partnerType)
        to.params['partnerId'] = to.meta['partnerId'] = String(res.data.partnerId)
        to.params['key'] = to.meta['key'] = getUrlKey(to)
      } else if (to.meta['domainDetached']) {
        throw new RedirectError({ name: 'notFound' })
      }
    })
}

export const validateDomain = (to: RouteLocationNormalized) => {
  if (!getPartner(to).partnerId) {
    return Promise.resolve()
  }
  return useNetSchoolStore()
    .getOrgDetail()
    .catch(() => {})
    .then((res) => {
      if (import.meta.env.DEV || to.meta['roleManager'] || isEmbed()) {
        return
      }
      // domain check
      const url = res?.data?.customDomain?.domainHost || import.meta.env.VITE_SITE_URL
      const domain = url.replace(/^http(?:s)?:\/\//, '')
      if (domain !== location.host) {
        redirectUrl(new DomainRedirectError(location.href.replace(location.host, domain)))
      }
    })
}
