import { getPartner } from '@common/router/index'
import { useProducerStore } from '@/store/producer'

export const getClassDetailRouteLocationNamedRaw = ({ productId, query = {} }) => {
  return {
    name: 'MallClassDetail',
    query: {
      ...query,
      ...useProducerStore().query,
      productId,
    },
    params: getPartner(),
  }
}

export default [
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/OpenClass/Share',
    name: 'MallOpenClassRedirect',
    component: () => import('../../views/pc/client/mall/OpenClassDetailRedirect'),
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/Redirect',
    name: 'MallFrameRedirect',
    component: () => import('../../views/pc/client/frame/FrameRedirect.js'),
    meta: {
      mallBaseInfo: { school: true },
    },
  },
]
