import {
  ORDER_STATUS,
  PAY_STATUS_FAIL,
  PAY_TYPE,
  PRODUCT_TYPE,
} from '@shared/constants/order'
import { PAY_CHANEL_DIFFERENT, PAY_CHANEL_DIFFERENT_MSG } from '@shared/constants/hr'
import { classPurchase } from '@common/api/px/mall/purchase'
import { isEmbed } from '@common/util/embed.client'
import { getOrderInfo } from '@common/api/api/purchase'

export const isPayFail = status => status == null || PAY_STATUS_FAIL.includes(status)
export const isPaying = status => status === ORDER_STATUS.enums.WaitToPay.value
export const isPaySuccess = status => status === ORDER_STATUS.enums.Complete.value
export const hasPaid = status => isPaySuccess(status) || status === ORDER_STATUS.enums.ShipmentFailed.value
export const getPayStatus = item => ({
  paying: isPaying(item.payStatus),
  success: isPaySuccess(item.payStatus),
  fail: isPayFail(item.payStatus),
  name: isPayFail(item.payStatus)
    ? `已取消${item.message ? `(${item.message})` : ''}`
    : ORDER_STATUS.get(item.payStatus)?.name || '未知',
})

export const filterOrderToDisplay = (orders, organizationId) => {
  return (orders || []).filter((order) => {
    return (
      organizationId === order.organizationId
      && [
        PRODUCT_TYPE.enums.CourseYxk.value,
        PRODUCT_TYPE.enums.CourseOrg.value,
        PRODUCT_TYPE.enums.CourseOrgOffline.value,
        PRODUCT_TYPE.enums.CourseOrgForYxk.value,
        PRODUCT_TYPE.enums.CourseOrgBag.value,
        PRODUCT_TYPE.enums.Mock.value,
      ].includes(order.productType)
    )
  })
}

export const purchaseInOrder = ({ id, schoolId, payType = PAY_TYPE.Wechat, specificPayType, code }) => {
  return classPurchase({
    orderId: id,
    schoolId,
    payType,
    specificPayType,
    code,
  })
    .then((res) => {
      if (!res.data || !res.data.prePayResponseInfo) {
        throw new Error('获取支付信息失败，请稍后再试')
      }
      if (isPaySuccess(res.data?.status)) {
        throw new Error('支付已完成')
      }
      if (isPayFail(res.data?.status)) {
        throw new Error('该课程暂时无法购买，如有疑问请联系机构老师')
      }
      return res.data
    })
    .catch((e) => {
      if (isEmbed() && e.message === '特约子商户商户号未授权服务商的产品权限') {
        throw new Error('暂不支持app支付，请联系老师')
      }
      if (e.hr === PAY_CHANEL_DIFFERENT) {
        throw new Error(PAY_CHANEL_DIFFERENT_MSG)
      }
      throw new Error(e.message || '该课程暂时无法购买，如有疑问请联系机构老师')
    })
}

export const genPurchaseNativeLoop = (params = { maxLoop: 4, loopTime: 30000, needImmediate: false }) => {
  let timer = null
  const listenLoop = ({ id, successCallback, failCallback, count = 0 }) => {
    const getInfo = () => {
      let loopContinue = true
      getOrderInfo({ id })
        .then((res) => {
          if (isPaySuccess(res.data?.status)) {
            loopContinue = false
            successCallback && successCallback(res.data)
          } else if (isPayFail(res.data?.status)) {
            loopContinue = false
            failCallback && failCallback(res.data)
          }
        })
        .finally(() => {
          if (loopContinue && count < params.maxLoop) {
            listenLoop({
              id,
              count: ++count,
              successCallback,
              failCallback,
            })
          }
        })
    }
    if (params.needImmediate) {
      params.needImmediate = false
      getInfo()
    } else {
      timer = setTimeout(() => getInfo(), params.loopTime)
    }
  }
  const clearLoop = () => clearTimeout(timer)

  return { listenLoop, clearLoop }
}
