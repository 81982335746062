import { getPartner } from '@common/router/index'
import { ApiOptions, getRequest, getRequestWithCache } from '../../index'

interface ShareGetProducerParams {
  code?: string | undefined
  cardType: number
}
interface ShareGetProducerData {
  producerId: number
  showContact: boolean
}
export const shareGetProducer = (params: ShareGetProducerParams) => {
  const { partnerId } = getPartner()
  return getRequestWithCache<ShareGetProducerData >(
    new ApiOptions({
      url: '/net/course/Svc/Share/GetProducer',
      args: { ...params, organizationId: partnerId },
    }),
  )
}

export const shareGetJoinClassProducer = (className: string) => {
  const { partnerId } = getPartner()
  return getRequest<ShareGetProducerData>(
    new ApiOptions({
      url: '/net/course/Svc/Share/JoinClassProducer',
      args: { className, organizationId: partnerId },
    }),
  )
}
