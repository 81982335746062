import { DEFAULT_ROUTES } from '../route'
import frame from './frame'
import home from './home'
import mobile from './mobile'
import mobileQuiz from './mobile-quiz'
import student from './student'

export const ROUTES = [
  {
    path: '/mall',
    component: () => import('../../components/pc/PcRoute.vue'),
    children: [
      {
        path: '',
        name: 'SiteHome',
        component: () => import('../../views/pc/client/mall/home/HomeView.vue'),
        meta: {
          domainDetached: true,
          title: '首页',
          mallBaseInfo: true,
          mobilePage: 'MobileStudentHome',
        },
      },
      {
        path: 'login',
        name: 'SiteLogin',
        component: () => import('../../views/pc/client/auth/login/LoginView.vue'),
        meta: {
          domainDetached: true,
          title: '登录',
          mallBaseInfo: { school: true },
          roleAnonymous: true,
          mobilePage: 'MobileLogin',
        },
      },
    ],
  },
  {
    path: '/mall',
    component: () => import('../../components/mobile/MobileRoute.vue'),
    children: [
      ...mobile,
      ...mobileQuiz,
    ],
  },
  {
    path: '/mall',
    name: 'MallPcHome',
    component: () => import('../../components/pc/PcRoute.vue'),
    children: [
      ...home,
      ...student,
      ...frame,
      {
        path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/closed',
        name: 'PlatformClose',
        component: () => import('../../views/pc/client/PlatformClose.vue'),
        meta: { permissionValid: () => Promise.resolve() },
      },
      ...DEFAULT_ROUTES,
    ],
  },
]
