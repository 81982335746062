import { RedirectError } from '@shared/constants/error'
import { delay } from '@shared/util/delay'
import qs from 'qs'
import type {
  LocationQuery,
  LocationQueryRaw,
  RouteLocationNormalized,
  RouteLocationRaw,
  RouteParamsRaw,
  Router,
} from 'vue-router'
import { getPartner, getUrlKey } from '@common/router/index'
import { callWxyxtApp } from '@common/constants/mall/callApp'
import { useProducerStore } from '@/store/producer'

/**
 * base64 路由参数校验
 * @usage meta: { requireKey: true }
 */
export const validHash = (to: RouteLocationNormalized) => {
  if (!to.meta.requireKey || !to.params['key'] || to.params['partnerId'] == null) {
    return Promise.resolve()
  }
  if (to.params['key'] !== getUrlKey(to)) {
    return Promise.reject(new RedirectError({ name: 'notFoundSpecify', params: getPartner(to) }))
  }
  return Promise.resolve()
}

const parseExtraUrl = (extraUrl: string, query: LocationQuery) => {
  const { partnerType, partnerId } = getPartner()
  return [
    extraUrl
      .replace('${courseDomain}', location.origin)
      .replace('${partnerType}', partnerType)
      .replace('${partnerId}', partnerId),
    qs.stringify({ ...query, embed: undefined }),
  ].join('?')
}
/**
 * 页面环境检测跳转
 * @usage meta: { mobileUrl: '' } | { mobilePage: '' } | { pcUrl: '' } | { pcPage: '' }
 */
export const validAdaptive = (to: RouteLocationNormalized) => {
  const params = getPartner()
  if (useMediaQuery('(min-width: 1184px)').value) {
    if (to.meta.pcPage) {
      return Promise.reject(new RedirectError({ name: to.meta.pcPage, params, query: to.query }))
    } else if (to.meta.pcUrl) {
      return Promise.reject(new RedirectError(null, parseExtraUrl(to.meta.pcUrl, to.query)))
    }
  } else {
    if (to.meta.mobilePage) {
      return Promise.reject(new RedirectError({ name: to.meta.mobilePage, params, query: to.query }))
    } else if (to.meta.mobileUrl) {
      return Promise.reject(new RedirectError(null, parseExtraUrl(to.meta.mobileUrl, to.query)))
    } else if (to.meta.mobileCallApp) {
      callWxyxtApp()
      return delay(10000)
    }
  }
  return Promise.resolve()
}

export const enhanceRoute = (router: Router) => {
  const originalPush = router.push
  const originalReplace = router.replace

  let producer: ReturnType<typeof useProducerStore>
  function getValue(target: ReturnType<typeof router.resolve>, params: RouteParamsRaw | null, key: string) {
    return (target.meta[key] ?? target.params[key] ?? params?.[key]) as string
  }
  function navigateProducerEnhance(originalFn: typeof originalPush, to: RouteLocationRaw) {
    const toParams = typeof to === 'string' ? null : 'params' in to ? to.params : null
    const target = router.resolve(to)
    target.meta.partnerType = getValue(target, toParams, 'partnerType')
    target.meta.partnerId = getValue(target, toParams, 'partnerId')
    target.meta.key = getValue(target, toParams, 'key')
    producer = producer || useProducerStore()
    const query = { ...producer.query, ...(target.query || {}) } as LocationQueryRaw
    if (location.search.includes('inApp')) {
      query['inApp'] = null
    }
    return originalFn({ ...target, query })
  }

  router.push = function push(to: RouteLocationRaw) {
    return navigateProducerEnhance(originalPush, to)
  }
  router.replace = function push(to: RouteLocationRaw) {
    return navigateProducerEnhance(originalReplace, to)
  }
}
