import { Constant } from '@shared/constants/index'

export const MOBILE_MODULES = Object.freeze(
  new Constant({
    Home: {
      index: 0,
      value: 'home',
      route: { name: 'MobileStudentHome' },
    },
    Course: {
      index: 1,
      value: 'course',
      route: { name: 'MobileStudentCourse' },
    },
    Mine: {
      index: 2,
      value: 'mine',
      route: { name: 'MobileStudentMine' },
    },
  }),
)

export default [
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)',
    component: () => import('@/components/mobile/MobileNavPageCacheable.vue'),
    children: [
      {
        path: '',
        name: 'MobileStudentRedirect',
        redirect: to => ({
          name: MOBILE_MODULES.enums.Home.route.name,
          params: to.params,
        }),
      },
      {
        path: 'home',
        name: MOBILE_MODULES.enums.Home.route.name,
        component: () => import('../../views/mobile/mall/home/MobileHomeCacheable.vue'),
        meta: {
          title: '首页',
          module: MOBILE_MODULES.enums.Home,
          pcPage: 'Home',
          mallBaseInfo: true,
        },
      },
      {
        path: 'course',
        name: MOBILE_MODULES.enums.Course.route.name,
        component: () => import('../../views/mobile/mall/course/MobileCourse.vue'),
        meta: {
          title: '课程',
          module: MOBILE_MODULES.enums.Course,
          pcPage: 'CourseCenter',
        },
      },
      {
        path: 'mine',
        name: MOBILE_MODULES.enums.Mine.route.name,
        component: () => import('../../views/mobile/mall/mine/MobileMine.vue'),
        meta: {
          title: '我的',
          module: MOBILE_MODULES.enums.Mine,
          mallBaseInfo: { user: true },
        },
      },
    ],
  },

  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/mine/info',
    name: 'MobileMyInfo',
    component: () => import('../../views/mobile/mall/mine/MobileMinePersonalInfo.vue'),
    meta: {
      title: '个人资料',
      requiresAuth: true,
      roleStudent: true,
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/order/confirm',
    name: 'MobileOrderConfirm',
    component: () => import('../../views/mobile/mall/purchase/MobileOrderConfirm.vue'),
    meta: {
      title: '确认订单',
      requiresAuth: true,
      roleStudent: true,
      requireKey: true,
      pcPage: 'OrderConfirm',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/mine/order',
    name: 'MobileMyOrder',
    component: () => import('../../views/mobile/mall/mine/order/MobileMineOrder.vue'),
    meta: {
      title: '我的订单',
      requiresAuth: true,
      roleStudent: true,
      requireKey: true,
      pcPage: 'StudentOrder',
      loginWithSsnToken: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/mine/coupon',
    name: 'MobileMyCoupon',
    component: () => import('../../views/mobile/mall/mine/MobileMineCoupon.vue'),
    meta: {
      title: '我的优惠券',
      requiresAuth: true,
      roleStudent: true,
      requireKey: true,
      pcPage: 'StudentCoupon',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/mine/redeem',
    name: 'MobileMyRedeem',
    component: () => import('../../views/mobile/mall/mine/redeem/MobileMineRedeemCodeCheck.vue'),
    meta: {
      title: '商品兑换',
      requiresAuth: true,
      roleStudent: true,
      requireKey: true,
      pcPage: 'StudentRedeemWare',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/mine/redeemCodeDetail',
    name: 'MobileRedeemCodeDetail',
    component: () => import('../../views/mobile/mall/mine/redeem/MobileRedeemCodeDetail.vue'),
    meta: {
      title: '商品兑换详情',
      requiresAuth: true,
      roleStudent: true,
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/mine/activity',
    name: 'MobileMyActivity',
    component: () => import('../../views/mobile/mall/mine/MobileMineActivity.vue'),
    meta: {
      title: '我的活动',
      requiresAuth: true,
      roleStudent: true,
      requireKey: true,
      pcPage: 'StudentActivity',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/mine/note',
    name: 'MobileMineNote',
    component: () => import('../../views/mobile/mall/mine/studyTool/note/MobileMineNoteList.vue'),
    meta: {
      title: '笔记',
      requiresAuth: true,
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/mine/note/filter',
    name: 'MobileNoteFilter',
    component: () => import('../../views/mobile/mall/mine/studyTool/components/MobileNoteFilter.vue'),
    meta: {
      title: '选择',
      requiresAuth: true,
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/mine/questionAsk',
    name: 'MobileMineQuestionAsk',
    component: () => import('../../views/mobile/mall/mine/studyTool/questionAsk/MobileMineQuestionAskList.vue'),
    meta: {
      title: '提问',
      requiresAuth: true,
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/order/purchase',
    name: 'MobileOrderPurchase',
    component: () => import('../../views/mobile/mall/mine/order/MobileWxPurchase.vue'),
    meta: {
      title: '订单购买',
      requiresAuth: true,
      roleStudent: true,
      requireKey: true,
      mallBaseInfo: { school: true },
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/order/result',
    name: 'MobileOrderResult',
    component: () =>
      import('../../views/mobile/mall/mine/order/MobilePurchaseResult.vue'),
    meta: {
      title: '支付结果',
      requiresAuth: true,
      roleStudent: true,
      requireKey: true,
    },
  },

  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/mine/notification',
    name: 'MobileMyNotification',
    component: () => import('../../views/mobile/mall/mine/notification/MobileNotification.vue'),
    meta: {
      title: '消息通知',
      requiresAuth: true,
      roleStudent: true,
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/mine/notificationDetail',
    name: 'MobileNotificationDetail',
    component: () =>
      import('../../views/mobile/mall/mine/notification/MobileNotificationDetail.vue'),
    meta: {
      title: '消息详情',
      requiresAuth: true,
      roleStudent: true,
      requireKey: true,
    },
  },

  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/search',
    name: 'MobileSearch',
    component: () => import('../../views/mobile/mall/MobileSearch.vue'),
    meta: {
      title: '搜索',
      requireKey: true,
      pcPage: 'SearchResult',
    },
  },

  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/teach',
    name: 'MobileTeach',
    component: () => import('../../views/mobile/mall/MobileTeach.vue'),
    meta: {
      title: '师资团队',
      requireKey: true,
      pcPage: 'Teach',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/guide',
    name: 'MobileGuide',
    component: () => import('../../views/mobile/mall/MobileGuide.vue'),
    meta: {
      title: '报考指南',
      requireKey: true,
      pcPage: 'Guide',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/material',
    name: 'MobileMaterial',
    component: () => import('../../views/mobile/mall/content/MobileMaterial.vue'),
    meta: {
      title: '考试资料',
      requireKey: true,
      pcPage: 'Material',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/questionBank',
    name: 'MobileQuestionBank',
    component: () => import('../../views/mobile/mall/content/MobileQuestionBank.vue'),
    meta: {
      title: '题库下载',
      requireKey: true,
      pcPage: 'QuestionBank',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/couponCenter',
    name: 'MobileCouponCenter',
    component: () => import('../../views/mobile/mall/MobileCouponCenter.vue'),
    meta: {
      title: '领券中心',
      requireKey: true,
      pcPage: 'CouponCenter',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/openClass',
    name: 'MobileOpenClass',
    component: () => import('../../views/mobile/mall/MobileOpenClassList.vue'),
    meta: {
      title: '公开课列表',
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/coupon/detail',
    name: 'MobileCouponDetail',
    component: () => import('../../views/mobile/mall/activity/couponDetail/MobileCouponDetail.vue'),
    meta: {
      title: '优惠券详情',
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/activity/detail',
    name: 'MobileActivityDetail',
    component: () => import('../../views/mobile/mall/activity/activityDetail/MobileActivityDetail.vue'),
    meta: {
      title: '活动详情',
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/distribution/detail',
    name: 'MobileDistributionDetail',
    component: () => import('../../views/mobile/mall/activity/distributionDetail/MobileDistributionDetail.vue'),
    meta: {
      title: '分销活动详情',
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/questionnaire/detail',
    name: 'MobileQuestionnaireDetail',
    component: () => import('../../views/mobile/mall/activity/questionnaireDetail/MobileQuestionnaireDetail.vue'),
    meta: {
      title: '问卷调查',
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/distribution/detail/shareWare',
    name: 'MobileShareWare',
    component: () => import('../../views/mobile/mall/activity/distributionDetail/ShareWareDetail.vue'),
    meta: {
      title: '分享商城商品',
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/activity/community',
    name: 'MobileActivityCommunity',
    component: () => import('../../views/mobile/mall/activity/community/MobileStudentCommunityDetail.vue'),
    meta: {
      title: '社群活动详情',
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/about',
    name: 'MobileAboutUs',
    component: () => import('../../views/mobile/mall/about/MobileAboutUs.vue'),
    meta: {
      title: '关于我们',
      requireKey: true,
      pcPage: 'About',
    },
  },

  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/course/list',
    name: 'MobileCourseList',
    component: () => import('../../views/mobile/mall/course/MobileCourseList.vue'),
    meta: {
      title: '课程列表',
      requireKey: true,
      pcPage: 'CourseCenter',
    },
  },

  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/login',
    name: 'MobileLogin',
    component: () => import('../../views/mobile/mall/login/MobileLogin.vue'),
    meta: {
      title: '登录/注册',
      roleAnonymous: true,
      requireKey: true,
      pcPage: 'Login',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/Trial/Detail',
    name: 'MobileTrialDetail',
    component: () => import('../../views/mobile/mall/courseDetail/MobileCourseDetail.vue'),
    meta: {
      title: '课程详情 - 云享课',
      requireKey: true,
      pcPage: 'MallClassDetail',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/Trial/SingleTrial',
    name: 'MobileSingleTrial',
    component: () => import('../../views/mobile/mall/courseDetail/MobileCourseSingleTrial.vue'),
    meta: {
      title: '课程试听 - 云享课',
      requireKey: true,
      pcPage: 'PCSingleTrial',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/Trial/Trial',
    name: 'MobileTrial',
    component: () => import('../../views/mobile/mall/courseDetail/MobileCourseTrial.vue'),
    meta: {
      title: '课程试听',
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/Trial/Comment',
    name: 'MobileCourseComment',
    component: () => import('../../views/mobile/mall/courseDetail/MobileCourseComment.vue'),
    meta: {
      title: '课程详情 - 课程评价',
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/Trial/Comment/Edit',
    name: 'MobileCourseCommentEdit',
    component: () => import('../../views/mobile/mall/courseDetail/MobileCourseCommentEdit.vue'),
    meta: {
      title: '课程详情 - 写评价',
      requireKey: true,
    },
  },

  {
    path: 'mobile/interconnect/bindWx',
    name: 'MobileBindWx',
    component: () => import('../../views/mobile/mall/bind/MobileBindWx.vue'),
    meta: {
      title: '微信绑定',
      ignoreUserValid: true,
    },
  },

  {
    path: 'mobile/custom/app',
    name: 'MobileCustomApp',
    component: () => import('../../views/mobile/mall/download/MobileCustomAppDownload.vue'),
    meta: {
      title: 'APP下载',
      ignoreUserValid: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/study',
    name: 'MobileJoinClassPage',
    component: () => import('../../views/mobile/mall/joinClass/MobileJoinClassPage.vue'),
    meta: {
      title: '选择学习方式',
      roleStudent: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/wx/authorize/:result(success|fail)',
    name: 'MobileWxAuthorizeResult',
    component: () => import('../../views/mobile/mall/MobileWxAuthorizeResult.vue'),
    meta: {
      title: '代开发小程序-授权结果',
      requiresAuth: false,
      requireKey: true,
    },
  },

  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/material/share',
    name: 'MobileMaterialShare',
    component: () => import('../../views/mobile/mall/activity/activityDetail/MobileMaterialShare.vue'),
    meta: {
      title: '资料领取下载',
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/material/detail',
    name: 'MobileMaterialShareDetail',
    component: () => import('../../views/mobile/mall/activity/activityDetail/MobileMaterialShareDetail.vue'),
    meta: {
      title: '资料领取下载',
      requireKey: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/Certificate/MobileCertificateSearch',
    name: 'MobileCertificateSearch',
    component: () => import('../../views/mobile/mall/certificate/MobileCertificateSearchCacheable.vue'),
    meta: {
      title: '证书查询系统',
      requireKey: true,
      pcPage: 'CertificateSearch',
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/Certificate/MobileCertificateDetail',
    name: 'MobileCertificateDetail',
    component: () => import('../../views/mobile/mall/certificate/MobileCertificateDetail.vue'),
    meta: {
      title: '证书详情',
      requireKey: true,
    },
  },
]
