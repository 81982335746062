export function useRouteLength() {
  const historyLength = useStorage('px-mall-history-length', 0)
  const init = () => (historyLength.value = window.history.length)
  const hasBack = () => window.history.length > historyLength.value

  return {
    init,
    hasBack,
  }
}
