import { PRODUCT_TYPE } from '@shared/constants/order'
import { Toast } from 'vant'
import { getPartner } from '@common/router'
import { APP_PAGE_NAME, PAGE_MALL_EVENT, doSupportEmbed, isEmbed } from '@common/util/embed.client'
import { getOrderInfo } from '@common/api/api/purchase'
import { hasPaid, isPayFail } from './pay'

const SUCCESS_STATUS = 1
const FAIL_STATUS = -1
export function injectPurchaseMethod() {
  window.PxPurchaseSdk = Object.freeze({
    LoopOrderStatus(timeout: number, orderId: number, ref?: string) {
      const getInfo = () => {
        getOrderInfo({ id: orderId }).then((res) => {
          if (isPayFail(res.data?.status) || hasPaid(res.data?.status)) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            clearLoop()
            const { partnerId } = getPartner()
            if (res.data?.productType === PRODUCT_TYPE.enums.Mock.value) {
              doSupportEmbed(() => {}, {
                event: PAGE_MALL_EVENT.Jump,
                meta: {
                  data: APP_PAGE_NAME.TuitionOrderResult,
                  status: hasPaid(res.data?.status) ? SUCCESS_STATUS : FAIL_STATUS,
                },
              })
            } else {
              doSupportEmbed(() => {}, {
                event: PAGE_MALL_EVENT.OrderSuccess,
              })
              window.location.href = `${
                import.meta.env.VITE_SITE_URL
              }/${partnerId}/Coursebag/OrderResult?orderNum=${res.data?.orderNum}${
                isEmbed() ? '&inApp=' : ''
              }${ref ? `&ref=${encodeURIComponent(ref)}` : ''}`
            }
          }
        })
      }
      getInfo()
      const interval = setInterval(getInfo, 1000)
      const clearLoop = () => {
        clearInterval(interval)
        Toast.clear(true)
      }
      setTimeout(() => clearLoop(), timeout)
    },
  })
}
