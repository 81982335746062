import type { Offer } from '@shared/constants/purchase'
import { ApiOptions, getRequest, postRequest } from '..'

interface OfferRequest {
  productIds?: string[]
  activityId?: number
}
export const getOffers = (params: OfferRequest) => {
  return getRequest<Offer[]>(
    new ApiOptions({
      url: '/net/api/purchase/getOffers',
      args: params,
    }),
  )
}

interface PurchaseHistoryRequest {
  payStatus?: number
  productId?: string
}
export interface PurchaseHistoryItem {
  id: number
  orderNum: String
  offerId: string
  offerName: string
  offerType: number
  offerPrice: number
  orderDiscounts: number
  allowPayType: number[]
  payType: number
  payStatus: number
  orderPayAmount: number
  message: string
  orderTime: number
  purchaseTime: number
  expiredTime: number
  productId: string
  productName: string
  productType: number
  organizationId: number
  schoolId: number
  schoolName: String
  iconImg: String
  subOrders: PurchaseHistoryItem[]
}
export const getPurchaseHistory = (params: PurchaseHistoryRequest) => {
  return getRequest<PurchaseHistoryItem[]>(
    new ApiOptions({
      url: '/net/api/purchase/history',
      args: params,
    }),
  )
}

export const cancelOrder = ({ orderId }: { orderId: number }) => {
  return postRequest(
    new ApiOptions({
      url: '/net/api/order/cancel',
      args: { orderId },
    }),
  )
}

export interface OrderInfo {
  id: number
  orderNum: string
  orderTime: number
  payTime: number
  status: number
  statusMsg: string
  orderAmount: number
  orderDiscounts: number
  offerId: string
  productId: string
  productType: number
}
export const getOrderInfo = ({ id }: { id: number }) => {
  return getRequest<OrderInfo>(
    new ApiOptions({
      url: '/net/api/order/getOrderInfo',
      args: { id },
    }),
  )
}
