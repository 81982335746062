import { NoPermissionError, RedirectError } from '@shared/constants/error'
import { useNetSchoolStore } from '@common/store/netSchool'
import { MALL_MODULE_MENU } from '@common/constants/mall/nav'

export const replacePath = path => path.replace(/:partnerType\(\[A-Za-z\]\+\)\/:partnerId\(\[0-9\]\+\)\/:key\(\[\^\/\]\+\)(?:\/home)?/, '')

const modulePermissionValid = (to, moduleId, childId) => {
  const school = useNetSchoolStore()
  return school.getInfo().then((res) => {
    if (!res?.data?.valid) {
      throw new RedirectError({ name: 'PlatformClose', params: to.params })
    }
    if (!school.isModuleShow(moduleId) && (childId === undefined || !school.isModuleShow(childId))) {
      throw new NoPermissionError()
    }
  })
}

export default [
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/home',
    name: 'Home',
    component: () => import('../../views/pc/client/mall/home/HomeView.vue'),
    meta: {
      title: '首页',
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileStudentHome',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/search',
    name: 'SearchResult',
    component: () => import('../../views/pc/client/mall/SearchResult.vue'),
    meta: {
      title: '搜索结果',
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileSearch',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/about',
    name: 'About',
    component: () => import('../../views/pc/client/mall/AboutUs.vue'),
    meta: {
      title: '关于我们',
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileAboutUs',
      permissionValid: to => modulePermissionValid(to, MALL_MODULE_MENU.AboutUs,
        MALL_MODULE_MENU.HomeAboutUs),
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/teach',
    name: 'Teach',
    component: () => import('../../views/pc/client/mall/teach/TeachTeam.vue'),
    meta: {
      title: '师资团队',
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileTeach',
      permissionValid: to => modulePermissionValid(to, MALL_MODULE_MENU.TeacherGroup,
        MALL_MODULE_MENU.HomeTeacherGroup),
    },
  },

  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/courseCenter',
    name: 'CourseCenter',
    component: () => import('../../views/pc/client/mall/CourseCenter.vue'),
    meta: {
      title: '选课中心',
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileStudentCourse',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/order/confirm',
    name: 'OrderConfirm',
    component: () => import('../../views/pc/client/mall/purchase/OrderConfirm.vue'),
    meta: {
      title: '确认订单',
      requiresAuth: true,
      roleStudent: true,
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileOrderConfirm',
    },
  },

  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/material',
    name: 'Material',
    component: () => import('../../views/pc/client/mall/content/ExamMaterial.vue'),
    meta: {
      title: '考试资料',
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileMaterial',
      permissionValid: to => modulePermissionValid(to, MALL_MODULE_MENU.Material,
        MALL_MODULE_MENU.HomeMaterial),
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/questionBank',
    name: 'QuestionBank',
    component: () => import('../../views/pc/client/mall/content/QuestionBank.vue'),
    meta: {
      title: '题库下载',
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileQuestionBank',
      permissionValid: to => modulePermissionValid(to, MALL_MODULE_MENU.Question,
        MALL_MODULE_MENU.HomeQuestion),
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/coupon',
    name: 'CouponCenter',
    component: () => import('../../views/pc/client/mall/CouponCenter.vue'),
    meta: {
      title: '领券中心',
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileCouponCenter',
      permissionValid: to => modulePermissionValid(to, MALL_MODULE_MENU.CouponCenter,
        MALL_MODULE_MENU.HomeCouponCenter),
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/guide',
    name: 'Guide',
    component: () => import('../../views/pc/client/mall/GuideView.vue'),
    meta: {
      title: '报考指南',
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileGuide',
      permissionValid: to => modulePermissionValid(to, MALL_MODULE_MENU.ExamGuide,
        MALL_MODULE_MENU.HomeExamGuide),
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/download',
    name: 'AppDownload',
    component: () => import('../../views/pc/client/mall/download/AppDownload.vue'),
    meta: {
      title: 'app下载',
      mallBaseInfo: true,
      requireKey: true,
      permissionValid: to => modulePermissionValid(to, MALL_MODULE_MENU.AppDownload),
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/Trial/Detail',
    name: 'MallClassDetail',
    component: () => import('../../views/pc/client/mall/courseDetail/MallCourseDetail.vue'),
    meta: {
      title: '课程详情',
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileTrialDetail',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/Trial/SingleTrial',
    name: 'PCSingleTrial',
    component: () => import('../../views/pc/client/mall/courseDetail/CourseTrial.vue'),
    meta: {
      title: '课程试听 - 云享课',
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileSingleTrial',
    },
  },

  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/register',
    name: 'Register',
    component: () => import('../../views/pc/client/auth/login/LoginView.vue'),
    meta: {
      title: '注册',
      mallBaseInfo: { school: true },
      roleAnonymous: true,
      requireKey: true,
      mobilePage: 'MobileLogin',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/login',
    name: 'Login',
    component: () => import('../../views/pc/client/auth/login/LoginView.vue'),
    meta: {
      title: '登录',
      mallBaseInfo: { school: true },
      roleAnonymous: true,
      requireKey: true,
      mobilePage: 'MobileLogin',
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/distributor/login',
    name: 'DistributorLogin',
    component: () => import('../../views/pc/client/auth/login/DistributorLogin.vue'),
    meta: {
      title: '分销商登录',
      mallBaseInfo: { school: true },
      roleAnonymous: true,
      requireKey: true,
      distributor: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/distributor/choose',
    name: 'ChooseOrganization',
    component: () => import('../../views/pc/client/auth/login/ChooseOrganization.vue'),
    meta: {
      title: '选择机构',
      mallBaseInfo: true,
      requireKey: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/bindPhone',
    name: 'BindPhone',
    component: () => import('../../views/pc/client/auth/bind/BindPhone.vue'),
    meta: {
      title: '绑定手机号',
      mallBaseInfo: true,
      requireKey: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/forceResetPassword',
    name: 'ForceResetPassword',
    component: () => import('../../views/pc/client/auth/password/ForceResetPassword.vue'),
    meta: {
      title: '修改密码',
      mallBaseInfo: true,
      requireKey: true,
    },
  },
  {
    path: ':partnerType([A-Za-z]+)/:partnerId([0-9]+)/:key([^/]+)/Certificate/CertificateSearch',
    name: 'CertificateSearch',
    component: () => import('../../views/pc/client/certificate/CertificateSearch.vue'),
    meta: {
      title: '证书查询系统',
      mallBaseInfo: true,
      requireKey: true,
      mobilePage: 'MobileCertificateSearch',
    },
  },
]
