export default [
  {
    // http://localhost:3007/mall/mobile/center/1433/quiz/chooseType?classId=6511&courseId=57602&inApp
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/quiz/chooseType',
    name: 'MobileQuizChooseType',
    component: () => import('../../views/mobile/study/quiz/MobileQuizChooseType.vue'),
    meta: {
      title: '题库类型选择',
      requiresAuth: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/quiz/treeList',
    name: 'MobileQuizTreeList',
    component: () => import('../../views/mobile/study/quiz/MobileQuizTreeList.vue'),
    meta: {
      title: '题库列表',
      requiresAuth: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/quiz/detail',
    name: 'MobileQuizDetail',
    component: () => import('../../views/mobile/study/quiz/MobileQuizDetail.vue'),
    meta: {
      title: '题库详情',
      requiresAuth: true,
    },
  },
  {
    // http://localhost:3007/mall/mobile/center/1433/quiz/answer?classId=2423&courseId=63761&quizId=1678323591961251844&inApp
    // 作业 Homework
    // http://localhost:3007/mall/mobile/center/1433/quiz/answer?classId=6511&courseId=57602&quizId=6542834a1aa8410001c7addd&inApp
    // http://localhost:3007/mall/mobile/center/1433/quiz/answer?classId=6511&courseId=57602&quizId=654284a81aa8410001c7addf&inApp
    // http://localhost:3007/mall/mobile/center/1433/quiz/answer?classId=6511&courseId=57602&quizId=654286331aa8410001c7ade5&inApp
    // 考试 SimulatedExam
    // http://localhost:3007/mall/mobile/center/1433/quiz/answer?classId=6511&courseId=57602&quizId=654286c21aa8410001c7ade6&inApp
    // http://localhost:3007/mall/mobile/center/1433/quiz/answer?classId=6511&courseId=57602&quizId=654286e21aa8410001c7ade7&inApp
    // http://localhost:3007/mall/mobile/center/1433/quiz/answer?classId=6511&courseId=57602&quizId=654287071aa8410001c7ade8&inApp
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/quiz/answer',
    name: 'MobileQuizAnswer',
    component: () => import('../../views/mobile/study/quiz/MobileQuizAnswer.vue'),
    meta: {
      title: '答题',
      requiresAuth: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/quiz/analysis',
    name: 'MobileQuizAnalysis',
    component: () => import('../../views/mobile/study/quiz/MobileQuizAnswer.vue'),
    meta: {
      title: '题目解析',
      requiresAuth: true,
    },
    props: {
      isAnalysisMode: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/quiz/list',
    name: 'MobileQuizList',
    component: () => import('../../views/mobile/study/quiz/MobileQuizList.vue'),
    meta: {
      title: '错题/收藏题',
      requiresAuth: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/quiz/result',
    name: 'MobileQuizResult',
    component: () => import('../../views/mobile/study/quiz/MobileQuizResult.vue'),
    meta: {
      title: '答题结果',
      requiresAuth: true,
    },
  },
  {
    path: 'mobile/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/quiz/note/detail',
    name: 'MobileQuizNoteDetail',
    component: () => import('../../views/mobile/study/quiz/MobileQuizNoteDetail.vue'),
    meta: {
      title: '题目解析',
      requiresAuth: true,
    },
  },
]
