<template>
  <router-view />
</template>

<script setup>
import { useHead } from '@unhead/vue'
import { RedirectError } from '@shared/constants/error'
import { setCookie } from '@shared/util/cookie'
import { useNetSchoolStore } from '@common/store/netSchool'
import { injectPurchaseMethod } from '@common/util/purchase/purchase.embed'
import { injectEmbedListener } from '@common/util/embed.client'
import { useProducerStore } from '@/store/producer'
import { validAdaptive } from '@/router/client/util'

const route = useRoute()
const school = useNetSchoolStore()
const iconEl = document.head.querySelector('link[rel="icon"]')
useHead({
  title: computed(() => {
    const title = route.meta?.title || ''
    if (school.pageLogo) {
      iconEl.href = school.pageLogo
    }
    if (school.pageTitle) {
      return `${school.pageTitle} - ${title}`
    }
    return `网校商城 - ${title}`
  }),
})
useProducerStore().registerProducerListener()

const router = useRouter()
useEventListener(window, 'resize', () => {
  validAdaptive(route).catch((e) => {
    if (e instanceof RedirectError) {
      if (e.route) {
        router.replace(e.route)
      } else {
        location.href = e.url
      }
    }
  })
})

injectEmbedListener()
injectPurchaseMethod()
setCookie('__environment', import.meta.env.VITE_BUSINESS_ENV)
</script>

<style lang="scss">
@import '@shared/style/color';
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark;
  background: $page-bg;
}
</style>
