import { NoPermissionError, RedirectError } from '@shared/constants/error'
import { delay } from '@shared/util/delay'
import NProgress from '@shared/util/nprogress'
import { routerModuleErrorHandler } from '@shared/util/router'
import { createRouter, createWebHistory } from 'vue-router'
import { PxBiConfig, pxPageViewBiLogger } from '@common/api/px/bi'
import { getPartner } from '@common/router/index'
import { isEmbed } from '@common/util/embed.client'
import { USER_ROLE } from '@common/constants/role'
import {
  getBaseInfo,
  getModuleInfo,
  setTokenFromRoute,
  validMetaPermission,
  validPassword,
  validPlatformIsClose,
  validRole,
  validToken,
} from '../util'
import { gotoLogin } from '../util.redirect'
import { ROUTES } from './route'
import { enhanceRoute, validAdaptive, validHash } from './util'
import { redirectUrl, validIndependentDomain, validateDomain } from './util.domain'
import { useRouteLength } from '@/compositions/useRoute'

const router = createRouter({ history: createWebHistory('/'), routes: ROUTES })
routerModuleErrorHandler(router)
enhanceRoute(router)

const route = ref(router.currentRoute.value)
router.beforeEach((to, from, next) => {
  route.value = to
  if (to.name !== from.name) {
    NProgress.start()
  }
  if (to.name == null || to.name === 'notFound') {
    pxPageViewBiLogger.log({
      biz: PxBiConfig.Biz,
      appName: to.path.includes('/mobile/') ? PxBiConfig.Apps.MallMobile : PxBiConfig.Apps.Mall,
      pageUrl: to.path,
      pageName: to.meta?.title || to.name,
      referPage: from.meta?.title || from.name,
    })
    NProgress.done()
    next(to.name == null ? { path: '/mall/notFound' } : undefined)
    return
  }
  return validHash(to, from)
    .then(() => setTokenFromRoute(to, from))
    .then(() => validIndependentDomain(to, from))
    .then(() => validAdaptive(to, from))
    .then(() => validToken(to, from))
    .then(() =>
      Promise.all([
        validPassword(to, from),
        validateDomain(to, from),
        getBaseInfo(to, from),
        getModuleInfo(to, from, USER_ROLE.STUDENT),
      ]),
    )
    .then(() => validRole(to, from))
    .then(() => validMetaPermission(to, from, { defaultPermissionFn: () => validPlatformIsClose(to) }))
    .then(() => next())
    .then(() => {
      if (to.meta?.title && to.name !== from.name) {
        pxPageViewBiLogger.log({
          biz: PxBiConfig.Biz,
          appName: to.path.includes('/mobile/') ? PxBiConfig.Apps.MallMobile : PxBiConfig.Apps.Mall,
          pageName: to.meta.title,
          referPage: from.meta.title,
        })
      }
    })
    .catch((err) => {
      const { partnerId } = getPartner(to)
      if (err instanceof RedirectError) {
        if (err.route) {
          next({ ...err.route, replace: true })
        } else {
          redirectUrl(err)
        }
      } else if (err instanceof NoPermissionError || partnerId) {
        if (to.query.ref) {
          location.href = to.query.ref
        } else if (isEmbed() && route.value.meta?.requiresAuth) {
          gotoLogin()
        } else {
          next({ name: 'Home', params: getPartner(to) })
        }
      } else {
        next({ path: '/mall/notFound' })
      }
    })
    .then(() => delay(50))
    .finally(() => NProgress.done())
})
router.afterEach((to) => {
  if (to.query.identity !== undefined || to.query.withoutIdentity !== undefined) {
    router.replace({ query: { ...to.query, identity: undefined, withoutIdentity: undefined } })
  }
})
useRouteLength().init()

export default router
export { route }
