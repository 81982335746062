import { browser } from '@shared/util/browser'
import { createScript } from '@shared/util/dom'
import { getQueryParam } from '@shared/util/url'
import { SHARE_TYPE } from '@common/constants/manager/share'
import { getWxShareInfo } from '@/api/px/mall/index'

if (browser.version.isWeixin) {
  const link = location.href
  const regResult = /mall(?:\/(?:mobile|detail))?\/[A-Za-z]+\/([0-9]+)/.exec(
    link,
  )
  if (regResult) {
    const organizationId = regResult[1]
    createScript({
      src: 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js',
    }, () => {
      getWxShareInfo({
        ...getContentParams(),
        url: link,
        organizationId,
      }).then((res) => {
        if (res.data) {
          setWeixinShare(res.data, {
            title: res.data.title,
            desc: res.data.subhead,
            link,
            imgUrl: res.data.iconUrl,
          })
        }
      })
    })
  }
}

function getContentParams() {
  const activityId = getQueryParam('activityId')
  if (activityId) {
    return {
      contentId: activityId,
      type: SHARE_TYPE.Activity,
    }
  }
  const productId = getQueryParam('productId')
  if (productId) {
    return {
      contentId: productId,
      type: SHARE_TYPE.Course,
    }
  }
  return {
    contentId: '',
    type: SHARE_TYPE.Mall,
  }
}

function setWeixinShare(config, info) {
  window.wx.config({
    appId: config.appId,
    timestamp: config.timestamp,
    nonceStr: config.nonceStr,
    signature: config.signature,
    jsApiList: [
      'updateAppMessageShareData',
      'updateTimelineShareData',
      'onMenuShareAppMessage',
      'onMenuShareTimeline',
    ],
  })

  window.wx.ready(() => {
    window.wx.updateAppMessageShareData?.(info)
    window.wx.updateTimelineShareData?.(info)
    window.wx.onMenuShareAppMessage?.(info)
    window.wx.onMenuShareTimeline?.(info)
  })

  window.wx.error((res) => {
    console.error(`error: ${res}`)
  })
}
