import { defineStore } from 'pinia'
import { computed, watchEffect } from 'vue'
import { isEmbed } from '@common/util/embed.client'
import { getPartner } from '@common/router'
import { getSelectedMarketInfo } from '@common/api/px/mall/index'
import { shareGetProducer } from '@common/api/px/mall/share'
import { CARD_TYPE } from '@common/constants/manager/share'

// TODO: router.replace会执行在路由加载完成之前 解决后重新提到common中
interface ContactType {
  isContact?: string
  moreContact?: string
  withoutContact?: string | undefined | null
}
interface IProductState {
  producerId: number | null
  encryptedProducerId: string | null
  query: {
    activityId?: string
    distributorId?: string
    unlimited?: string
    promote?: string
    marketInfoId?: string
  } & ContactType
}

export const useProducerStore = defineStore({
  id: 'producer',
  state: (): IProductState => ({
    producerId: null, // 分享产生者id
    encryptedProducerId: null,
    query: {},
  }),
  getters: {
    /**
     * 是否显示名片，true 显示，false 不显示
     */
    showContact: (state) => {
      return state.query.withoutContact === undefined
    },
    /**
     * 是否是联系人
     */
    isContact: (state) => {
      return state.query.isContact !== undefined && state.producerId
    },
    /**
     * 是否轮换联系人
     */
    moreContact: (state) => {
      return state.query.moreContact !== undefined
    },
    /**
     * 联系人 id，null 为机构默认联系人
     */
    contactUserId: (state: IProductState & ContactType) => {
      return state.isContact || state.moreContact ? state.producerId : null
    },
  },
  actions: {
    async getProducer() {
      const cardType = computed(() => {
        if (this.query.isContact !== undefined) {
          return CARD_TYPE.Mine
        }
        if (this.query.withoutContact !== undefined) {
          return CARD_TYPE.None
        }
        if (this.query.moreContact !== undefined) {
          return CARD_TYPE.More
        }
        return CARD_TYPE.Org
      })
      const result = await shareGetProducer({ code: this.query.promote, cardType: cardType.value })
      this.producerId = result.data?.producerId || null
      return result
    },
    registerProducerListener() {
      const route = useRoute()
      const router = useRouter()
      // eslint-disable-next-line complexity
      watchEffect(() => {
        if (route.name === undefined || route.meta['roleManager']) {
          return
        }
        if (route.query['activityId'] && route.query['distributorId']) {
          this.query.activityId = route.query['activityId'] as string
          this.query.distributorId = route.query['distributorId'] as string
        }
        if (route.query['unlimited']) {
          this.query.unlimited = route.query['unlimited'] as string
        }

        if (route.query['promote']) {
          if (this.query.promote !== route.query['promote']) {
            this.query.promote = route.query['promote'] as string
            this.query.isContact = route.query['isContact'] as string
            this.query.withoutContact = route.query['withoutContact'] as string
            this.query.moreContact = route.query['moreContact'] as string
            this.query.marketInfoId = route.query['marketInfoId'] as string
            this.getProducer().then(() =>
              router.replace({ query: { ...route.query, ...this.query } }),
            )
          }
        } else if (!this.query.marketInfoId && getPartner()['partnerId'] && !isEmbed()) {
          getSelectedMarketInfo().then((res) => {
            if (res.data) {
              this.query.marketInfoId = String(res.data.id)
              this.query.withoutContact = undefined
            } else {
              // 若无机构官方名片，则对应“以空名片分享”
              this.query.withoutContact = null
            }
          }).catch(() => {})
        }
      })
    },
    clearQuery() {
      this.producerId = null
      this.encryptedProducerId = null
      this.query = {}
      const route = useRoute()
      const router = useRouter()
      router.replace({
        query: {
          ...route.query,
          prompt: undefined,
          isContact: undefined,
          withoutContact: undefined,
          marketInfoId: undefined,
        },
      })
    },
  },
})
